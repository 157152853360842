<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.unionPay.header') }}</p>
            <p>{{ $t('deposit.unionPay.desc') }}</p>
            <ul>
              <li>{{ $t('deposit.unionPay.inst1') }}</li>
              <li>{{ $t('deposit.unionPay.inst2') }}</li>
              <li>{{ $t('deposit.unionPay.inst3') }}</li>
            </ul>
          </div>
          <div class="form_main">
            <p class="title">{{ $t('deposit.unionPay.form.header') }}</p>
            <el-form label-position="top" :model="unionForm" ref="unionForm" status-icon :rules="unionRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      supportedCurrencies="USD"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.amt')" prop="amount">
                      <numeric-input
                        v-model="unionForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li class="data">
                    <p>
                      {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'RMB' }) }}
                      <span> {{ rate }}</span>
                    </p>
                    <p>
                      RMB: <span>{{ rmbChange }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="unionForm.notes" :maxlength="256" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo diamond unionpay"></div>
      </div>
      <div v-html="bankHTML"></div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import { autoForm } from '@/util/getAutoForm';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import mixin from '@/mixins/page/deposit';
import { apiUnionpay } from '@/resource';

export default {
  name: 'unionPay',
  components: { NumericInput, AccountNumber, DefaultTemplate },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      unionForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      unionRules: {
        accountNumber: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      rate: 0.0,
      bankHTML: ''
    };
  },
  computed: {
    rmbChange() {
      return rounding(Math.ceil, this.rate * this.unionForm.amount, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.unionForm.accountNumber = accountNumber;
      this.fetchRate(accountNumber);
    },
    submitForm() {
      this.$refs['unionForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0 && result.data.data.success != false) {
                this.bankHTML = autoForm(result.data.data);
                if (this.bankHTML.length > 0) {
                  this.$nextTick(function() {
                    if (document.getElementById('AutoForm')) {
                      document.getElementById('AutoForm').submit();
                    } else {
                      this.loading = false;
                      this.fail(this.$t('deposit.default.failed'));
                    }
                  });
                } else {
                  this.loading = false;
                  this.fail(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                if (result.data.code !== 562) {
                  this.errorMessage(this.$t('deposit.default.failed'));
                }

                if (result.data.data.message != null) {
                  this.fail(result.data.data.message);
                } else {
                  this.fail(this.$t('deposit.default.failed'));
                }
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    fail(message) {
      this.errorMessage(message);
      setTimeout(() => {
        this.$router.go();
      }, 2500);
    },
    submitDeposit() {
      return apiUnionpay(
        {
          mt4Account: this.unionForm.accountNumber,
          operateAmount: this.unionForm.amount,
          applicationNotes: this.unionForm.notes
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
